import React from 'react';
 
const Error = () => {
    return (
        <div className="mainContainer">
            <p>404 Error: Page does not exist</p>
        </div>
    );
}
 
export default Error;